<template>
  <div class="aboutus">
    <div class="title">{{ $t("contact.t1") }}</div>
    <div class="container">
      <div class="content">
        <!-- 联系方式 -->
        <div class="contact-section" data-aos="fade-up">
          <div class="title-item">
            <h4 class="title-text">{{ $t("about.title2") }}</h4>
          </div>
          <div class="contact-content">
            <div class="contact-item">
              <i class="iconfont icon-dianhua"></i>
              <h4>{{ $t("contact.title1") }}</h4>
              <p>{{ $t("contact.title1_t1") }}</p>
              <p>{{ $t("contact.title1_t2") }}</p>
            </div>
            <div class="contact-item">
              <i class="iconfont icon-youxiang"></i>
              <h4>{{ $t("contact.title2") }}</h4>
              <p>{{ $t("contact.title2_t1") }}</p>
              <p>{{ $t("contact.title2_t2") }}</p>
            </div>
            <div class="contact-item">
              <i class="iconfont icon-qq"></i>
              <h4>{{ $t("contact.title3") }}</h4>
              <p>{{ $t("contact.title3_t1") }}</p>
              <p>{{ $t("contact.title3_t2") }}</p>
            </div>
            <div class="contact-item">
              <i class="iconfont icon-dizhi"></i>
              <h4>{{ $t("contact.title4") }}</h4>
              <p>{{ $t("contact.title4_t1") }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 位置 -->
      <div class="content">
        <div class="location" data-aos="fade-up">
          <div class="title-item">
            <h4 class="title-text">{{ $t("about.title4") }}</h4>
          </div>
          <div id="map">
            <baidu-map
              class="bm-view"
              :zoom="zoom"
              :center="center"
              @ready="handler"
              scroll-wheel-zoom
            >
              <bm-marker :position="{ lng: 121.525302, lat: 31.236256 }">
              </bm-marker>
            </baidu-map>
          </div>
        </div>
      </div>
      <!-- 询价 -->
      <div class="content">
        <div class="messages">
          <div class="title-item">
            <h4 class="title-text">{{ $t("about.title5") }}</h4>
          </div>
          <p class="msg-tip">{{ $t("contact.tip") }}</p>
          <div class="message-inputs">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              :label-position="labelPosition"
              class="demo-ruleForm"
            >
              <el-form-item>
                <el-col :span="11">
                  <el-form-item :label="$t('about.i1')" prop="userName">
                    <el-input v-model="ruleForm.userName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="2" style="opacity: 0">-</el-col>
                <el-col :span="11">
                  <el-form-item :label="$t('about.i5')" prop="company">
                    <el-input v-model="ruleForm.company"></el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item>
                <el-col :span="11">
                  <el-form-item :label="$t('about.i3')" prop="userEmail">
                    <el-input v-model="ruleForm.userEmail"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="2" style="opacity: 0">-</el-col>
                <el-col :span="11">
                  <el-form-item :label="$t('about.i2')" prop="userPhone">
                    <el-input v-model="ruleForm.userPhone"></el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item :label="$t('about.i4')" prop="message">
                <el-input type="textarea" v-model="ruleForm.message"></el-input>
              </el-form-item>
              <el-form-item style="text-align: center">
                <el-button type="primary" @click="submitForm('ruleForm')">{{
                  $t("about.send")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <back-top></back-top>
  </div>
</template>

<script>
import { addMessage } from "../../network/msg";
import BackTop from "../../components/content/backTop/BackTop.vue";
export default {
  name: "AboutUs",
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3,
      labelPosition: "top",
      ruleForm: {
        userName: "",
        userEmail: "",
        userPhone: "",
        message: "",
        company:""
      },
      rules: {
        userName: [
          { min: 2, max: 24, message: this.$t("about.tip1"), trigger: "blur" },
        ],
        userPhone: [
          { min: 2, max: 24, message: this.$t("about.tip1"), trigger: "blur" },
        ],
        userEmail: [
          { required: true, message: this.$t("about.tip2"), trigger: "blur" },
          {
            type: "email",
            message: this.$t("about.tip3"),
            trigger: ["blur", "change"],
          },
        ],
        message: [
          { required: true, message: this.$t("about.tip4"), trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    // 设置地图点
    handler({ BMap, map }) {
      // console.log(BMap,map);
      this.center.lng = 121.525302;
      this.center.lat = 31.236256;
      this.zoom = 15;
    },
    //发送留言
    submitForm(formName) {
      // console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addMessage(this.ruleForm).then((res) => {
            // console.log(res);
            if (res.status !== "ok") {
              return this.$message.error(this.$t("about.send_fail"));
            }
            this.$message.success(this.$t("about.send_success"));
            this.$refs[formName].resetFields();
          });
        } else {
          return false;
        }
      });
    },
  },
  components: {
    BackTop,
  },
};
</script>

<style scoped lang="less">
@import "../../assets/css/AboutUs.less";
</style>
